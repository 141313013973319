import { template as template_f3acbb36c84940dc906a2d6a4728e91b } from "@ember/template-compiler";
const FKControlMenuContainer = template_f3acbb36c84940dc906a2d6a4728e91b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
